/* eslint-disable */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import US from '../assets/translationFiles/EN.json';
import FR from '../assets/translationFiles/FR.json';
import DE from '../assets/translationFiles/DE.json';
import ES from '../assets/translationFiles/ES.json';
import IT from '../assets/translationFiles/IT.json';
import NL from '../assets/translationFiles/NL.json';
import SV from '../assets/translationFiles/SV.json';
import CS from '../assets/translationFiles/CS.json';
import PL from '../assets/translationFiles/PL.json';

const getDefaultLanguage = () => {
  let lang = new URLSearchParams(window.location.search).get('lang');
  if (lang) {
    localStorage.setItem('locale', lang || 'EN');
  } else if (!lang && localStorage.getItem('locale')) {
    lang = localStorage.getItem('locale');
  }

  return lang || 'EN';
};

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      'EN': { translation: { ...US }},
      'FR': { translation: { ...FR }},
      'IT': { translation: { ...IT }},
      'ES': { translation: { ...ES }},
      'DE': { translation: { ...DE }},
      'NL': { translation: { ...NL }},
      'PL': { translation: { ...PL }},
      'SV': { translation: { ...SV }},
      'CS': { translation: { ...CS }},
    },
    load: 'languageOnly',
    lng: getDefaultLanguage(),
    keySeparator: false,
    nsSeparator: '::',
    interpolation: {
      escapeValue: false,
      format(value, format, lng) {
        switch (format) {
          case 'date':
            if (value.hasOwnProperty('date') && value.hasOwnProperty('timeZone')) {
              return new Intl.DateTimeFormat(lng, {
                timeZone: value.timeZone,
              }).format(value.date);
            }
            break;
          case 'longDate':
            if (value.hasOwnProperty('date') && value.hasOwnProperty('timeZone')) {
              return new Intl.DateTimeFormat(lng, {
                timeZone: value.timeZone,
                year: "numeric",
                month: "short",
                day: "numeric",
              }).format(value.date);
            }
            break;
          case 'number':
            if (!isNaN(value)) {
              return new Intl.NumberFormat(lng).format(value);
            }
            if (value.hasOwnProperty('number') && value.hasOwnProperty('currency')) {
              return new Intl.NumberFormat(lng, {
                style: 'currency',
                currency: value.currency,
              }).format(value.number);
            }
            break;

          case 'time':
            if (value.hasOwnProperty('time') && value.hasOwnProperty('timeZone')) {
              return new Intl.DateTimeFormat(lng, {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZone: value.timeZone,
                timeZoneName: 'short',
              }).format(value.time);
            }
            break;
          default:
            return value;
            break;
        }
      },
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['h3', 'p', 'ul', 'li', 'br'],
    },
    fallbackLng: false
  });

export default i18n;
