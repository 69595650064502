/* eslint-disable */
const allCountries = {
  AF: 'Afghanistan',
  AX: 'Aland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua & Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: {
    country: 'Australia',
    statesOrRegions:
      {
        'Australian Capital Territory': {name: 'Australian Capital Territory', timezone: 'Australia/Sydney'},
        'New South Wales': {name: 'New South Wales', timezone: 'Australia/Sydney'},
        'Northern Territory': {name: 'Northern Territory', timezone: 'Australia/Darwin'},
        Queensland: {name: 'Queensland', timezone: 'Australia/Brisbane'},
        'South Australia': {name: 'South Australia', timezone: 'Australia/Adelaide'},
        Tasmania: {name: 'Tasmania', timezone: 'Australia/Hobart'},
        Victoria: {name: 'Victoria', timezone: 'Australia/Melbourne'},
        'Western Australia': {name: 'Western Australia', timezone: 'Australia/Perth'},
      },
  },
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia & Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory (the)',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: {
    country: 'Canada',
    statesOrRegions:
      {
        AB: {name: 'Alberta', timezone: 'America/Edmonton'},
        BC: {name: 'British Columbia', timezone: 'America/Vancouver'},
        MB: {name: 'Manitoba', timezone: 'America/Winnipeg'},
        NB: {name: 'New Brunswick', timezone: 'America/Moncton'},
        NL: {name: 'Newfoundland and Labrador', timezone: 'America/St_Johns'},
        NS: {name: 'Nova Scotia', timezone: 'America/Halifax'},
        NT: {name: 'Northwest Terr.', timezone: 'America/Cambridge_Bay'},
        NU: {name: 'Nunavut', timezone: 'America/Iqaluit'},
        ON: {name: 'Ontario', timezone: 'America/Toronto'},
        PE: {name: 'Prince Edward Is.', timezone: 'America/Moncton'},
        QC: {name: 'Quebec', timezone: 'America/Toronto'},
        SK: {name: 'Saskatchewan', timezone: 'America/Regina'},
        YT: {name: 'Yukon', timezone: 'America/Whitehorse'},
      },
  },
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CD: 'Congo, Democratic Republic of (DRC)',
  CG: 'Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: 'Côte d\'Ivoire',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard and Mcdonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: {
    country: 'Ireland',
    statesOrRegions: {
      Carlow: {name: 'Carlow', timezone: 'Europe/Dublin'},
      Cavan: {name: 'Cavan', timezone: 'Europe/Dublin'},
      Clare: {name: 'Clare', timezone: 'Europe/Dublin'},
      Cork: {name: 'Cork', timezone: 'Europe/Dublin'},
      Donegal: {name: 'Donegal', timezone: 'Europe/Dublin'},
      Dublin: {name: 'Dublin', timezone: 'Europe/Dublin'},
      Galway: {name: 'Galway', timezone: 'Europe/Dublin'},
      Kerry: {name: 'Kerry', timezone: 'Europe/Dublin'},
      Kildare: {name: 'Kildare', timezone: 'Europe/Dublin'},
      Kilkenny: {name: 'Kilkenny', timezone: 'Europe/Dublin'},
      Laois: {name: 'Laois', timezone: 'Europe/Dublin'},
      Leitrim: {name: 'Leitrim', timezone: 'Europe/Dublin'},
      Limerick: {name: 'Limerick', timezone: 'Europe/Dublin'},
      Longford: {name: 'Longford', timezone: 'Europe/Dublin'},
      Louth: {name: 'Louth', timezone: 'Europe/Dublin'},
      Mayo: {name: 'Mayo', timezone: 'Europe/Dublin'},
      Meath: {name: 'Meath', timezone: 'Europe/Dublin'},
      Monaghan: {name: 'Monaghan', timezone: 'Europe/Dublin'},
      Offaly: {name: 'Offaly', timezone: 'Europe/Dublin'},
      Roscommon: {name: 'Roscommon', timezone: 'Europe/Dublin'},
      Sligo: {name: 'Sligo', timezone: 'Europe/Dublin'},
      Tipperary: {name: 'Tipperary', timezone: 'Europe/Dublin'},
      Waterford: {name: 'Waterford', timezone: 'Europe/Dublin'},
      Westmeath: {name: 'Westmeath', timezone: 'Europe/Dublin'},
      Wexford: {name: 'Wexford', timezone: 'Europe/Dublin'},
      Wicklow: {name: 'Wicklow', timezone: 'Europe/Dublin'},
    },
  },
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'Korea, Democratic Republic of (North Korea)',
  KR: 'Korea, Republic of (South Korea)',
  XK: 'Kosovo',
  KW: 'Kuwait',
  KG: 'Kyrgyz Republic (Kyrgyzstan)',
  LA: 'Laos',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands (the)',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: {
    country: 'Mexico',
    statesOrRegions: {
      Aguascalientes: {name: 'Aguascalientes', timezone: 'America/Mexico_City'},
      'Baja California': {name: 'Baja California', timezone: 'America/Tijuana'},
      'Baja California Sur': {name: 'Baja California Sur', timezone: 'America/Mazatlan'},
      Campeche: {name: 'Campeche', timezone: 'America/Merida'},
      Chiapas: {name: 'Chiapas', timezone: 'America/Merida'},
      Chihuahua: {name: 'Chihuahua', timezone: 'America/Chihuahua'},
      Coahuila: {name: 'Coahuila', timezone: 'America/Monterrey'},
      Colima: {name: 'Colima', timezone: 'America/Mexico_City'},
      'Distrito Federal': {name: 'Distrito Federal', timezone: 'America/Mexico_City'},
      Durango: {name: 'Durango', timezone: 'America/Monterrey'},
      Guanajuato: {name: 'Guanajuato', timezone: 'America/Mexico_City'},
      Guerrero: {name: 'Guerrero', timezone: 'America/Mexico_City'},
      Hidalgo: {name: 'Hidalgo', timezone: 'America/Mexico_City'},
      Jalisco: {name: 'Jalisco', timezone: 'America/Mexico_City'},
      Mexico: {name: 'Mexico', timezone: 'America/Mexico_City'},
      Michoacan: {name: 'Michoacan', timezone: 'America/Mexico_City'},
      Morelos: {name: 'Morelos', timezone: 'America/Mexico_City'},
      Nayarit: {name: 'Nayarit', timezone: 'America/Mazatlan'},
      'Nuevo Leon': {name: 'Nuevo Leon', timezone: 'America/Monterrey'},
      Oaxaca: {name: 'Oaxaca', timezone: 'America/Mexico_City'},
      Puebla: {name: 'Puebla', timezone: 'America/Mexico_City'},
      Queretaro: {name: 'Queretaro', timezone: 'America/Mexico_City'},
      'Quintana Roo': {name: 'Quintana Roo', timezone: 'America/Cancun'},
      'San Luis Potosi': {name: 'San Luis Potosi', timezone: 'America/Mexico_City'},
      Sinaloa: {name: 'Sinaloa', timezone: 'America/Mazatlan'},
      Sonora: {name: 'Sonora', timezone: 'America/Hermosillo'},
      Tabasco: {name: 'Tabasco', timezone: 'America/Merida'},
      Tamaulipas: {name: 'Tamaulipas', timezone: 'America/Monterrey'},
      Tlaxcala: {name: 'Tlaxcala', timezone: 'America/Mexico_City'},
      Veracruz: {name: 'Veracruz', timezone: 'America/Mexico_City'},
      Yucatan: {name: 'Yucatan', timezone: 'America/Merida'},
      Zacatecas: {name: 'Zacatecas', timezone: 'America/Monterrey'},
    },
  },
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova, Republic of',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar/Burma',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands (the)',
  NC: 'New Caledonia',
  NZ: {
    country: 'New Zealand',
    statesOrRegions: {
      Auckland: {name: 'Auckland', timezone: 'Pacific/Auckland'},
      'Bay of Plenty': {name: 'Auckland', timezone: 'Pacific/Auckland'},
      Canterbury: {name: 'Canterbury', timezone: 'Pacific/Auckland'},
      'Chatham Islands': {name: 'Chatham Islands', timezone: 'Pacific/Chatham'},
      Gisborne: {name: 'Gisborne', timezone: 'Pacific/Auckland'},
      'Hawke\'s Bay': {name: 'Hawke\'s Bay', timezone: 'Pacific/Auckland'},
      'Manawatu-Wanganui': {name: 'Manawatu-Wanganui', timezone: 'Pacific/Auckland'},
      Marlborough: {name: 'Marlborough', timezone: 'Pacific/Auckland'},
      Nelson: {name: 'Nelson', timezone: 'Pacific/Auckland'},
      Northland: {name: 'Northland', timezone: 'Pacific/Auckland'},
      Otago: {name: 'Otago', timezone: 'Pacific/Auckland'},
      Southland: {name: 'Southland', timezone: 'Pacific/Auckland'},
      Taranaki: {name: 'Taranaki', timezone: 'Pacific/Auckland'},
      Waikato: {name: 'Waikato', timezone: 'Pacific/Auckland'},
      Wellington: {name: 'Wellington', timezone: 'Pacific/Auckland'},
      'West Coast': {name: 'West Coast', timezone: 'Pacific/Auckland'},
    },
  },
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MK: 'Macedonia, Republic of',
  MP: 'Northern Mariana Islands (the)',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovak Republic (Slovakia)',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TW: 'Taiwan (Province of China)',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad & Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks & Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: {
    country: 'United Kingdom',
    statesOrRegions: {
      'Aberconwy and Colwyn': {name: 'Aberconwy and Colwyn', timezone: 'Europe/London'},
      'Aberdeen City': {name: 'Aberdeen City', timezone: 'Europe/London'},
      Aberdeenshire: {name: 'Aberdeenshire', timezone: 'Europe/London'},
      Anglesey: {name: 'Anglesey', timezone: 'Europe/London'},
      Angus: {name: 'Angus', timezone: 'Europe/London'},
      Antrim: {name: 'Antrim', timezone: 'Europe/London'},
      'Argyll and Bute': {name: 'Argyll and Bute', timezone: 'Europe/London'},
      Armagh: {name: 'Armagh', timezone: 'Europe/London'},
      Avon: {name: 'Avon', timezone: 'Europe/London'},
      Ayrshire: {name: 'Ayrshire', timezone: 'Europe/London'},
      'Bath and NE Somerset': {name: 'Bath and NE Somerset', timezone: 'Europe/London'},
      Bedfordshire: {name: 'Bedfordshire', timezone: 'Europe/London'},
      Belfast: {name: 'Belfast', timezone: 'Europe/London'},
      Berkshire: {name: 'Berkshire', timezone: 'Europe/London'},
      Berwickshire: {name: 'Berwickshire', timezone: 'Europe/London'},
      BFPO: {name: 'BFPO', timezone: 'Europe/London'},
      'Blaenau Gwent': {name: 'Blaenau Gwent', timezone: 'Europe/London'},
      Buckinghamshire: {name: 'Buckinghamshire', timezone: 'Europe/London'},
      Caernarfonshire: {name: 'Caernarfonshire', timezone: 'Europe/London'},
      Caerphilly: {name: 'Caerphilly', timezone: 'Europe/London'},
      Caithness: {name: 'Caithness', timezone: 'Europe/London'},
      Cambridgeshire: {name: 'Cambridgeshire', timezone: 'Europe/London'},
      Cardiff: {name: 'Cardiff', timezone: 'Europe/London'},
      Cardiganshire: {name: 'Cardiganshire', timezone: 'Europe/London'},
      Carmarthenshire: {name: 'Carmarthenshire', timezone: 'Europe/London'},
      Ceredigion: {name: 'Ceredigion', timezone: 'Europe/London'},
      'Channel Islands': {name: 'Channel Islands', timezone: 'Europe/Guernsey'},
      Cheshire: {name: 'Cheshire', timezone: 'Europe/London'},
      'City of Bristol': {name: 'City of Bristol', timezone: 'Europe/London'},
      Clackmannanshire: {name: 'Clackmannanshire', timezone: 'Europe/London'},
      Clwyd: {name: 'Clwyd', timezone: 'Europe/London'},
      Conwy: {name: 'Conwy', timezone: 'Europe/London'},
      'Cornwall/Scilly': {name: 'Cornwall/Scilly', timezone: 'Europe/London'},
      Cumbria: {name: 'Cumbria', timezone: 'Europe/London'},
      Denbighshire: {name: 'Denbighshire', timezone: 'Europe/London'},
      Derbyshire: {name: 'Derbyshire', timezone: 'Europe/London'},
      'Derry/Londonderry': {name: 'Derry/Londonderry', timezone: 'Europe/London'},
      Devon: {name: 'Devon', timezone: 'Europe/London'},
      Dorset: {name: 'Dorset', timezone: 'Europe/London'},
      Down: {name: 'Down', timezone: 'Europe/London'},
      'Dumfries and Galloway': {name: 'Dumfries and Galloway', timezone: 'Europe/London'},
      Dunbartonshire: {name: 'Dunbartonshire', timezone: 'Europe/London'},
      Dundee: {name: 'Dundee', timezone: 'Europe/London'},
      Durham: {name: 'Durham', timezone: 'Europe/London'},
      Dyfed: {name: 'Dyfed', timezone: 'Europe/London'},
      'East Ayrshire': {name: 'East Ayrshire', timezone: 'Europe/London'},
      'East Dunbartonshire': {name: 'East Dunbartonshire', timezone: 'Europe/London'},
      'East Lothian': {name: 'East Lothian', timezone: 'Europe/London'},
      'East Renfrewshire': {name: 'East Renfrewshire', timezone: 'Europe/London'},
      'East Riding Yorkshire': {name: 'East Riding Yorkshire', timezone: 'Europe/London'},
      'East Sussex': {name: 'East Sussex', timezone: 'Europe/London'},
      Edinburgh: {name: 'Edinburgh', timezone: 'Europe/London'},
      England: {name: 'England', timezone: 'Europe/London'},
      Essex: {name: 'Essex', timezone: 'Europe/London'},
      Falkirk: {name: 'Falkirk', timezone: 'Europe/London'},
      Fermanagh: {name: 'Fermanagh', timezone: 'Europe/London'},
      Fife: {name: 'Fife', timezone: 'Europe/London'},
      Flintshire: {name: 'Flintshire', timezone: 'Europe/London'},
      Glasgow: {name: 'Glasgow', timezone: 'Europe/London'},
      Gloucestershire: {name: 'Gloucestershire', timezone: 'Europe/London'},
      'Greater London': {name: 'Greater London', timezone: 'Europe/London'},
      'Greater Manchester': {name: 'Greater Manchester', timezone: 'Europe/London'},
      Gwent: {name: 'Gwent', timezone: 'Europe/London'},
      Gwynedd: {name: 'Gwynedd', timezone: 'Europe/London'},
      Hampshire: {name: 'Hampshire', timezone: 'Europe/London'},
      Hartlepool: {name: 'Hartlepool', timezone: 'Europe/London'},
      'Hereford and Worcester': {name: 'Hereford and Worcester', timezone: 'Europe/London'},
      Hertfordshire: {name: 'Hertfordshire', timezone: 'Europe/London'},
      Highlands: {name: 'Highlands', timezone: 'Europe/London'},
      Inverclyde: {name: 'Inverclyde', timezone: 'Europe/London'},
      'Inverness-Shire': {name: 'Inverness-Shire', timezone: 'Europe/London'},
      'Isle of Man': {name: 'Isle of Man', timezone: 'Europe/Isle_of_Man'},
      'Isle of Wight': {name: 'Isle of Wight', timezone: 'Europe/London'},
      Kent: {name: 'Kent', timezone: 'Europe/London'},
      Kincardinshire: {name: 'Kincardinshire', timezone: 'Europe/London'},
      'Kingston Upon Hull': {name: 'Kingston Upon Hull', timezone: 'Europe/London'},
      'Kinross-Shire': {name: 'Kinross-Shire', timezone: 'Europe/London'},
      Kirklees: {name: 'Kirklees', timezone: 'Europe/London'},
      Lanarkshire: {name: 'Lanarkshire', timezone: 'Europe/London'},
      Lancashire: {name: 'Lancashire', timezone: 'Europe/London'},
      Leicestershire: {name: 'Leicestershire', timezone: 'Europe/London'},
      Lincolnshire: {name: 'Lincolnshire', timezone: 'Europe/London'},
      Londonderry: {name: 'Londonderry', timezone: 'Europe/London'},
      Merseyside: {name: 'Merseyside', timezone: 'Europe/London'},
      'Merthyr Tydfil': {name: 'Merthyr Tydfil', timezone: 'Europe/London'},
      'Mid Glamorgan': {name: 'Mid Glamorgan', timezone: 'Europe/London'},
      'Mid Lothian': {name: 'Mid Lothian', timezone: 'Europe/London'},
      Middlesex: {name: 'Middlesex', timezone: 'Europe/London'},
      Monmouthshire: {name: 'Monmouthshire', timezone: 'Europe/London'},
      Moray: {name: 'Moray', timezone: 'Europe/London'},
      'Neath Port Talbot': {name: 'Neath Port Talbot', timezone: 'Europe/London'},
      Newport: {name: 'Newport', timezone: 'Europe/London'},
      Norfolk: {name: 'Norfolk', timezone: 'Europe/London'},
      'North Ayrshire': {name: 'North Ayrshire', timezone: 'Europe/London'},
      'North East Lincolnshire': {name: 'North East Lincolnshire', timezone: 'Europe/London'},
      'North Lanarkshire': {name: 'North Lanarkshire', timezone: 'Europe/London'},
      'North Lincolnshire': {name: 'North Lincolnshire', timezone: 'Europe/London'},
      'North Somerset': {name: 'North Somerset', timezone: 'Europe/London'},
      'North Yorkshire': {name: 'North Yorkshire', timezone: 'Europe/London'},
      Northamptonshire: {name: 'Northamptonshire', timezone: 'Europe/London'},
      'Northern Ireland': {name: 'Northern Ireland', timezone: 'Europe/London'},
      Northumberland: {name: 'Northumberland', timezone: 'Europe/London'},
      Nottinghamshire: {name: 'Nottinghamshire', timezone: 'Europe/London'},
      'Orkney and Shetland Isles': {name: 'Orkney and Shetland Isles', timezone: 'Europe/London'},
      Oxfordshire: {name: 'Oxfordshire', timezone: 'Europe/London'},
      Pembrokeshire: {name: 'Pembrokeshire', timezone: 'Europe/London'},
      'Perth and Kinross': {name: 'Perth and Kinross', timezone: 'Europe/London'},
      Powys: {name: 'Powys', timezone: 'Europe/London'},
      'Redcar and Cleveland': {name: 'Redcar and Cleveland', timezone: 'Europe/London'},
      Renfrewshire: {name: 'Renfrewshire', timezone: 'Europe/London'},
      'Rhonda Cynon Taff': {name: 'Rhonda Cynon Taff', timezone: 'Europe/London'},
      Rutland: {name: 'Rutland', timezone: 'Europe/London'},
      'Scottish Borders': {name: 'Scottish Borders', timezone: 'Europe/London'},
      Shetland: {name: 'Shetland', timezone: 'Europe/London'},
      Shropshire: {name: 'Shropshire', timezone: 'Europe/London'},
      Somerset: {name: 'Somerset', timezone: 'Europe/London'},
      'South Ayrshire': {name: 'South Ayrshire', timezone: 'Europe/London'},
      'South Glamorgan': {name: 'South Glamorgan', timezone: 'Europe/London'},
      'South Gloucestershire': {name: 'South Gloucestershire', timezone: 'Europe/London'},
      'South Lanarkshire': {name: 'South Lanarkshire', timezone: 'Europe/London'},
      'South Yorkshire': {name: 'South Yorkshire', timezone: 'Europe/London'},
      Staffordshire: {name: 'Staffordshire', timezone: 'Europe/London'},
      Stirling: {name: 'Stirling', timezone: 'Europe/London'},
      'Stockton On Tees': {name: 'Stockton On Tees', timezone: 'Europe/London'},
      Suffolk: {name: 'Suffolk', timezone: 'Europe/London'},
      Surrey: {name: 'Surrey', timezone: 'Europe/London'},
      Swansea: {name: 'Swansea', timezone: 'Europe/London'},
      Torfaen: {name: 'Torfaen', timezone: 'Europe/London'},
      'Tyne and Wear': {name: 'Tyne and Wear', timezone: 'Europe/London'},
      Tyrone: {name: 'Tyrone', timezone: 'Europe/London'},
      'Vale Of Glamorgan': {name: 'Vale Of Glamorgan', timezone: 'Europe/London'},
      Wales: {name: 'Wales', timezone: 'Europe/London'},
      Warwickshire: {name: 'Warwickshire', timezone: 'Europe/London'},
      'West Berkshire': {name: 'West Berkshire', timezone: 'Europe/London'},
      'West Dunbartonshire': {name: 'West Dunbartonshire', timezone: 'Europe/London'},
      'West Glamorgan': {name: 'West Glamorgan', timezone: 'Europe/London'},
      'West Lothian': {name: 'West Lothian', timezone: 'Europe/London'},
      'West Midlands': {name: 'West Midlands', timezone: 'Europe/London'},
      'West Sussex': {name: 'West Sussex', timezone: 'Europe/London'},
      'West Yorkshire': {name: 'West Yorkshire', timezone: 'Europe/London'},
      'Western Isles': {name: 'Western Isles', timezone: 'Europe/London'},
      Wiltshire: {name: 'Wiltshire', timezone: 'Europe/London'},
      Wirral: {name: 'Wirral', timezone: 'Europe/London'},
      Worcestershire: {name: 'Worcestershire', timezone: 'Europe/London'},
      Wrexham: {name: 'Wrexham', timezone: 'Europe/London'},
      York: {name: 'York', timezone: 'Europe/London'},
    },
  },
  UM: 'United States Minor Outlying Islands (the)',
  US: {
    country: 'United States of America (USA)',
    statesOrRegions: {
      AL: {name: 'Alabama', timezone: 'America/Chicago'},
      AK: {name: 'Alaska', timezone: 'America/Juneau'},
      AZ: {name: 'Arizona', timezone: 'America/Phoenix'},
      AR: {name: 'Arkansas', timezone: 'America/Chicago'},
      CA: {name: 'California', timezone: 'America/Los_Angeles'},
      CO: {name: 'Colorado', timezone: 'America/Denver'},
      CT: {name: 'Connecticut', timezone: 'America/New_York'},
      DE: {name: 'Delaware', timezone: 'America/New_York'},
      FL: {name: 'Florida', timezone: 'America/New_York'},
      GA: {name: 'Georgia', timezone: 'America/New_York'},
      HI: {name: 'Hawaii', timezone: 'Pacific/Honolulu'},
      ID: {name: 'Idaho', timezone: 'America/Boise'},
      IL: {name: 'Illinois', timezone: 'America/Chicago'},
      IN: {name: 'Indiana', timezone: 'America/Chicago'},
      IA: {name: 'Iowa', timezone: 'America/Chicago'},
      KS: {name: 'Kansas', timezone: 'America/Chicago'},
      KY: {name: 'Kentucky', timezone: 'America/New_York'},
      LA: {name: 'Louisiana', timezone: 'America/Chicago'},
      ME: {name: 'Maine', timezone: 'America/New_York'},
      MD: {name: 'Maryland', timezone: 'America/New_York'},
      MA: {name: 'Massachusetts', timezone: 'America/New_York'},
      MI: {name: 'Michigan', timezone: 'America/New_York'},
      MN: {name: 'Minnesota', timezone: 'America/Chicago'},
      MS: {name: 'Mississippi', timezone: 'America/Chicago'},
      MO: {name: 'Missouri', timezone: 'America/Chicago'},
      MT: {name: 'Montana', timezone: 'America/Denver'},
      NE: {name: 'Nebraska', timezone: 'America/Chicago'},
      NV: {name: 'Nevada', timezone: 'America/Los_Angeles'},
      NH: {name: 'New Hampshire', timezone: 'America/New_York'},
      NJ: {name: 'New Jersey', timezone: 'America/New_York'},
      NM: {name: 'New Mexico', timezone: 'America/Denver'},
      NY: {name: 'New York', timezone: 'America/New_York'},
      NC: {name: 'North Carolina', timezone: 'America/New_York'},
      ND: {name: 'North Dakota', timezone: 'America/Chicago'},
      OH: {name: 'Ohio', timezone: 'America/New_York'},
      OK: {name: 'Oklahoma', timezone: 'America/Chicago'},
      OR: {name: 'Oregon', timezone: 'America/Los_Angeles'},
      PA: {name: 'Pennsylvania', timezone: 'America/New_York'},
      RI: {name: 'Rhode Island', timezone: 'America/New_York'},
      SC: {name: 'South Carolina', timezone: 'America/New_York'},
      SD: {name: 'South Dakota', timezone: 'America/Chicago'},
      TN: {name: 'Tennessee', timezone: 'America/Chicago'},
      TX: {name: 'Texas', timezone: 'America/Chicago'},
      UT: {name: 'Utah', timezone: 'America/Denver'},
      VT: {name: 'Vermont', timezone: 'America/New_York'},
      VA: {name: 'Virginia', timezone: 'America/New_York'},
      WA: {name: 'Washington', timezone: 'America/Los_Angeles'},
      WV: {name: 'West Virginia', timezone: 'America/New_York'},
      WI: {name: 'Wisconsin', timezone: 'America/Chicago'},
      WY: {name: 'Wyoming', timezone: 'America/Denver'},
    },
  },
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'Virgin Islands (UK)',
  VI: 'Virgin Islands (US)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

const acceptedCountries = [
  'AU', 'CA', 'IE', 'MX', 'NZ', 'GB', 'US',
].reduce((newObject, item) => (newObject[item] = allCountries[item], newObject), {});

const prepForDropDownCountries = Object.keys(acceptedCountries).map(item => ({ value: item, text: acceptedCountries[item].country }));

const prepForDropDownStatesPerCountry = (country) => {
  if (acceptedCountries.hasOwnProperty(country)) {
    return Object.keys(acceptedCountries[country].statesOrRegions)
      .map(item => ({
        value: item,
        text: acceptedCountries[country].statesOrRegions[item].name,
      }));
  }

  return [];
};

const returnTimeZone = (country, state) => {
  const newObject = Object.keys(acceptedCountries).reduce((newObject, item) => {
    newObject[item] = acceptedCountries[item].statesOrRegions;
    return newObject;
  }, {});

  let normalizedName;
  if(['united states', 'united states (usa)','usa','us'].includes(country.toLowerCase())) {
    normalizedName='US';
  }

  else if(['canada','ca','can'].includes(country.toLowerCase())) {
    normalizedName='CA';
  }

  else if(['australia','aus','au'].includes(country.toLowerCase())) {
    normalizedName='AU';
  }
  else if(['united kingdom','uk','gb'].includes(country.toLowerCase())) {
    normalizedName='GB';
  }
  else if(['ireland','ie','ire'].includes(country.toLowerCase())) {
    normalizedName='IE';
  }
  else if(['new zealand','nz'].includes(country.toLowerCase())) {
    normalizedName='NZ';
  }
  else if(['mexico','mx','mex'].includes(country.toLowerCase())) {
    normalizedName='MX';
  }
  else {
    normalizedName = null;
  }

  if(normalizedName) {
    if(newObject[normalizedName].hasOwnProperty(state)) {
      return newObject[normalizedName][state].timezone
    }
    else {
      let saveValue = Object.keys(newObject[normalizedName]).reduce((tempObject, item)=>{
        if(tempObject.hasOwnProperty(item)) {
          tempObject[newObject[normalizedName][item].timezone] =+ 1;
        }
        else {
          tempObject[newObject[normalizedName][item].timezone] = 1;
        }
        return tempObject;
      }, {})
      return Object.keys(saveValue).sort((a,b)=>saveValue[b]-saveValue[a])[0]

    }
  }
  else {
    return 'Europe/London'
  }
}

const countryMappedToCurrency = (country) => {
  const acceptedCountries = {
    'AU': 'AUD',
    'CA': 'CAD',
    'IE': 'EUR',
    'MX': 'USD',
    'NZ': 'AUD',
    'GB': 'GBP',
    'US': 'USD',
  }
  return acceptedCountries.hasOwnProperty(country) ? acceptedCountries[country] : 'USD'

}

const countryMappedToLanguage = (country) => {
  const acceptedCountries = {
    'AU': 'EN',
    'CA': 'EN',
    'IE': 'EN',
    'MX': 'EN',
    'NZ': 'EN',
    'GB': 'EN',
    'US': 'EN',
  }
  return acceptedCountries.hasOwnProperty(country) ? acceptedCountries[country] : 'USD'
}

const soleDirectorCountries = [
  'AUSTRALIA',
  'AU',
  'AUS',
  'AUD',

  'IRELAND',
  'IE',
  'IRE',
  'IRL',
  'EIRE',

  'England',
  'ENG',
  'ENGL',
  'EN',
  'E',

  'UNITED KINGDOM',
  'UK',

  'GREAT BRITAIN',
  'GB',
  'BRITAIN'
]

const localesSupported = ['EN', 'CS', 'DE', 'ES', 'FR', 'IT', 'NL', 'PL', 'SV'];

const localesSupportedMapped = {
  'EN': 'EN',
  'en-US': 'EN',
  'en-GB': 'EN',
  'en-NZ': 'EN',
  'en-AU': 'EN',
  'en': 'EN',
  'FR': 'FR',
  'fr-FR': 'FR',
  'fr-CA': 'FR',
  'fr-CH': 'FR',
  'fr': 'FR',
  'es-ES': 'ES',
  'es-US': 'ES',
  'ES': 'ES',
  'de-AT': 'DE',
  'de-BE': 'DE',
  'de-DE': 'DE',
  'DE': 'DE',
  'it-IT': 'IT',
  'ca-IT': 'IT',
  'fur-IT': 'IT',
  'sc-IT': 'IT',
  'scn-IT': 'IT',
  'IT': 'IT',
  'pl-PL': 'PL',
  'PL': 'PL',
  'se-SE': 'SV',
  'sv-SE': 'SV',
  'SV': 'SV',
  'nl-NL': 'NL',
  'nl-SX': 'NL',
  'nl-SR': 'NL',
  'nl-AW': 'NL',
  'nl-BQ': 'NL',
  'nl-CW': 'NL',
  'NL': 'NL',
  'cs-CZ': 'CS',
  'CS': 'CS',
};

const localesToHeaders = {
  'EN': 'English',
  CS: 'Čeština',
  'DE': 'Deutsch',
  'ES': 'Español',
  'FR': 'Français',
  'IT': 'Italiano',
  NL: 'Nederlands',
  PL: 'Polski',
  SV: 'Svenska',
};

const GMTMapping = {
  "Pacific/Pago_Pago":"GMT-11:00",
  "Pacific/Honolulu":"GMT-10:00",
  "America/Los_Angeles":"GMT-08:00",
  "America/Tijuana":"GMT-08:00",
  "America/Denver":"GMT-07:00",
  "America/Phoenix":"GMT-07:00",
  "America/Mazatlan":"GMT-07:00",
  "America/Edmonton":"GMT-07:00",
  "America/Chicago":"GMT-06:00",
  "America/Mexico_City":"GMT-06:00",
  "America/Regina":"GMT-06:00",
  "America/Guatemala":"GMT-06:00",
  "America/Bogota":"GMT-05:00",
  "America/New_York":"GMT-05:00",
  "America/Lima":"GMT-05:00",
  "America/Caracas":"GMT-04:30",
  "America/Halifax":"GMT-04:00",
  "America/Guyana":"GMT-04:00",
  "America/La_Paz":"GMT-04:00",
  "America/Argentina/Buenos_Aires":"GMT-03:00",
  "America/Godthab":"GMT-03:00",
  "America/Montevideo":"GMT-03:00",
  "America/St_Johns":"GMT-03:30",
  "America/Santiago":"GMT-03:00",
  "America/Sao_Paulo":"GMT-02:00",
  "Atlantic/South_Georgia":"GMT-02:00",
  "Atlantic/Azores":"GMT-01:00",
  "Atlantic/Cape_Verde":"GMT-01:00",
  "Africa/Casablanca":"GMT+00:00",
  "Europe/Dublin":"GMT+00:00",
  "Europe/Lisbon":"GMT+00:00",
  "Europe/London":"GMT+00:00",
  "Africa/Monrovia":"GMT+00:00",
  "Africa/Algiers":"GMT+01:00",
  "Europe/Amsterdam":"GMT+01:00",
  "Europe/Berlin":"GMT+01:00",
  "Europe/Brussels":"GMT+01:00",
  "Europe/Budapest":"GMT+01:00",
  "Europe/Belgrade":"GMT+01:00",
  "Europe/Prague":"GMT+01:00",
  "Europe/Copenhagen":"GMT+01:00",
  "Europe/Madrid":"GMT+01:00",
  "Europe/Paris":"GMT+01:00",
  "Europe/Rome":"GMT+01:00",
  "Europe/Stockholm":"GMT+01:00",
  "Europe/Vienna":"GMT+01:00",
  "Europe/Warsaw":"GMT+01:00",
  "Europe/Athens":"GMT+02:00",
  "Europe/Bucharest":"GMT+02:00",
  "Africa/Cairo":"GMT+02:00",
  "Asia/Jerusalem":"GMT+02:00",
  "Africa/Johannesburg":"GMT+02:00",
  "Europe/Helsinki":"GMT+02:00",
  "Europe/Kiev":"GMT+02:00",
  "Europe/Kaliningrad":"GMT+02:00",
  "Europe/Riga":"GMT+02:00",
  "Europe/Sofia":"GMT+02:00",
  "Europe/Tallinn":"GMT+02:00",
  "Europe/Vilnius":"GMT+02:00",
  "Europe/Istanbul":"GMT+03:00",
  "Asia/Baghdad":"GMT+03:00",
  "Africa/Nairobi":"GMT+03:00",
  "Europe/Minsk":"GMT+03:00",
  "Asia/Riyadh":"GMT+03:00",
  "Europe/Moscow":"GMT+03:00",
  "Asia/Tehran":"GMT+03:30",
  "Asia/Baku":"GMT+04:00",
  "Europe/Samara":"GMT+04:00",
  "Asia/Tbilisi":"GMT+04:00",
  "Asia/Yerevan":"GMT+04:00",
  "Asia/Kabul":"GMT+04:30",
  "Asia/Karachi":"GMT+05:00",
  "Asia/Yekaterinburg":"GMT+05:00",
  "Asia/Tashkent":"GMT+05:00",
  "Asia/Colombo":"GMT+05:30",
  "Asia/Almaty":"GMT+06:00",
  "Asia/Dhaka":"GMT+06:00",
  "Asia/Rangoon":"GMT+06:30",
  "Asia/Bangkok":"GMT+07:00",
  "Asia/Jakarta":"GMT+07:00",
  "Asia/Krasnoyarsk":"GMT+07:00",
  "Asia/Shanghai":"GMT+08:00",
  "Asia/Hong_Kong":"GMT+08:00",
  "Asia/Kuala_Lumpur":"GMT+08:00",
  "Asia/Irkutsk":"GMT+08:00",
  "Asia/Singapore":"GMT+08:00",
  "Asia/Taipei":"GMT+08:00",
  "Asia/Ulaanbaatar":"GMT+08:00",
  "Australia/Perth":"GMT+08:00",
  "Asia/Yakutsk":"GMT+09:00",
  "Asia/Seoul":"GMT+09:00",
  "Asia/Tokyo":"GMT+09:00",
  "Australia/Darwin":"GMT+09:30",
  "Australia/Brisbane":"GMT+10:00",
  "Pacific/Guam":"GMT+10:00",
  "Asia/Magadan":"GMT+10:00",
  "Asia/Vladivostok":"GMT+10:00",
  "Pacific/Port_Moresby":"GMT+10:00",
  "Australia/Adelaide":"GMT+10:30",
  "Australia/Hobart":"GMT+11:00",
  "Australia/Sydney":"GMT+11:00",
  "Pacific/Guadalcanal":"GMT+11:00",
  "Pacific/Noumea":"GMT+11:00",
  "Pacific/Majuro":"GMT+12:00",
  "Asia/Kamchatka":"GMT+12:00",
  "Pacific/Auckland":"GMT+13:00",
  "Pacific/Fakaofo":"GMT+13:00",
  "Pacific/Fiji":"GMT+13:00",
  "Pacific/Tongatapu":"GMT+13:00",
  "Pacific/Apia":"GMT+14:00"
}

export {
  allCountries,
  acceptedCountries,
  returnTimeZone,
  GMTMapping,
  prepForDropDownCountries,
  prepForDropDownStatesPerCountry,
  countryMappedToCurrency,
  countryMappedToLanguage,
  soleDirectorCountries,
  localesSupported,
  localesSupportedMapped,
  localesToHeaders,
};
