import ApiService from '../services/api/api';
import { str2ab, arrayBufferToBase64 } from './helpers';

class Encrypt {
  static publicKey = null;
  static publicKeyName = null;
  static auth = {
    access_token: null,
    token_type: 'Bearer',
    subscription_id: null,
    assureIDConnectEndpoint: 'https://preview.assureid.acuant.net',
    faceMatchEndpoint: 'https://preview.face.acuant.net',
  };

  static setPublicKey = (publicKey) => {
    Encrypt.publicKey = publicKey;
  }

  static setPublicKeyName = (publicKeyName) => {
    Encrypt.publicKeyName = publicKeyName;
  }

  static setAuth = (credentials) => {
    Encrypt.auth = credentials;
  }

  static getPublicKey = async (token, t) => {
    const responsePublicKey = await fetch(ApiService.IfgApiCallsList['All/GET_PK'], {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Authorization': `Bearer ${token}` },
    });

    if (responsePublicKey.ok && responsePublicKey.error !== '1') {
      const response = await responsePublicKey.json();
      Encrypt.setPublicKeyName(response.key_name);
      Encrypt.setAuth(response.credentials);

      const pemHeader = "-----BEGIN PUBLIC KEY-----";
      const pemFooter = "-----END PUBLIC KEY-----";
      const pemContents = response.public_key.substring(pemHeader.length, response.public_key.length - pemFooter.length);
      const binaryDerString = window.atob(pemContents);
      const binaryDer = str2ab(binaryDerString);

      try {
        const pk = await window.crypto.subtle.importKey(
          "spki",
          binaryDer,
          { name: "RSA-OAEP", hash: "SHA-1" },
          true,
          ["encrypt"]
        );
        Encrypt.setPublicKey(pk);
      } catch (e) {
        console.error('[err]',e);
        return ({
          pkError: `${e} - ${Encrypt.publicKeyName}`,
        })
      }

      const { access_token, subscription_id, assureIDConnectEndpoint, faceMatchEndpoint } = response.credentials;

      return ({
        publicKey: Encrypt.publicKey,
        publicKeyName: Encrypt.publicKeyName,
        AUTH_TOKEN: access_token,
        SUBSCRIPTION_ID: subscription_id,
        ASSURE_ENDPOINT: assureIDConnectEndpoint,
        FACE_MATCH_ENDPOINT: faceMatchEndpoint
      })
    } else {
      const err = responsePublicKey.status === 403
        ? t('Token in invalid. Please contact IFG representative')
        : t('Something went wrong. Please contact IFG representative');
      return ({
        pkError: err,
      })
    }
  }

  static encryptRSA = async (field, type) => {
    let item;

    switch (type) {
      case 'key':
        item = await window.crypto.subtle.exportKey('raw', field);
        break;
      case 'iv':
        item = field;
        break;
      default:
        let enc = new TextEncoder();
        item = enc.encode(field);
        break;
    }

    const encrypted_field = await window.crypto.subtle.encrypt({ name: "RSA-OAEP" }, Encrypt.publicKey, item);
    return `%enc:localhost:${Encrypt.publicKeyName}:${arrayBufferToBase64(encrypted_field)}%`;
  };

  static encryptAES = async (image, key, iv) => {
    if (!image) return null;

    const encrypted_image = await window.crypto.subtle.encrypt({ name: "AES-CBC", iv }, key, image);
    return arrayBufferToBase64(encrypted_image);
  };

  static generateAESKey = async () => {
    return window.crypto.subtle.generateKey({ name: "AES-CBC", length: 256 }, true, ["encrypt"]);
  };

  static generateIV = async () => {
    return window.crypto.getRandomValues(new Uint8Array(16));
  };
}

export default Encrypt;
