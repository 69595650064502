import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Header from "./Header";

class EulaPage extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        const token = new URLSearchParams(window.location.search).get('token') || localStorage.getItem('token');

        if (localStorage.getItem('acuantEula') === token) {
            this.props.history.push('/capture/photo');
        }
    }

    getOrientationCopy() {
        return this.props.orientation === 0 ? 'front' : 'back';
    }

    confirmEula(){
        const token = new URLSearchParams(window.location.search).get('token') || localStorage.getItem('token');

        localStorage.setItem('acuantEula', token);
        this.props.history.replace('/capture/photo');
    }

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <Header />
                <div className='body column capture_photo' style={{padding: "0 5% 0 5%"}}>
                    <div>
                        <h1>{t('End User License Agreement')}</h1>
                    </div>
                    <div style={{margin: "10% 0 10% 0"}}>
                        <p>{t('By activating or otherwise using IFG software, you accept all the terms and conditions of this agreement. If you do not accept the terms of this agreement, do not accept below. If you proceed to access and use IFG\'s Software, Your use will be deemed to be your unequivocal consent to have IFG process your personal identifiable information that can be used to identify you ("PII") and which may also include biometric data. Your PII and biometric data will be used to authenticate the authenticity of the identity document you submitted to Licensor and/or verify that the picture on the identification document is, in fact, the person standing in front of the camera. IFG will collect and store your PII, including biometric data in accordance with IFG\'s Global Privacy Disclosure Statement. Should you not want your PII or biometric data used in the manner described above, do not use Licensor\'s Software.')}</p>
                    </div>
                    <Fragment>
                        <label className='btn ifg' onClick={() => this.confirmEula()}>
                            <p className={'buttonBgText'}>{t('Accept')}</p>
                        </label>
                    </Fragment>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        orientation: state.idProperties.orientation,
        cardType: state.idProperties.cardType,
        sidesLeft: state.idProperties.sidesLeft,
        frontSubmitted: state.config.frontSubmitted,
        backSubmitted: state.config.backSubmitted
    };
}
export default connect(mapStateToProps, null)(withTranslation()(EulaPage));
