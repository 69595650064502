import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import Processing from "./../Processing";
import {bindActionCreators} from "redux";
import {processID} from '../actions/processDataActions';
import {resetProcessedData} from "../actions/processDataActions";
import {resetConfig} from "../actions/configActions";
import {resetIDProperties} from "../actions/idPropertiesActions";
import Header from "../Header";
import Spinner from '../Spinner';
import { convertProcessedData, ResultEnum } from '../../utilities/helpers';
import Encrypt from '../../utilities/Encrypt';
import ApiService from '../../services/api/api';
import {withTranslation} from "react-i18next";


class IDPassport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sendingData: true,
            fields: {}
        }
    }

    componentDidMount() {
        const { resultData } = this.props;
        if (process.env.REACT_APP_FRM_ENABLED === 'false') {
            let {instanceID} = this.props;
            this.props.processID(instanceID, true);
        }

        this.sendProcessedData(resultData);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { resultData } = this.props;
        if (resultData && !prevProps.resultData) {
            this.setState({ sendingData: true });
            this.sendProcessedData(resultData);

        }
    }

    async sendProcessedData(data) {
        const { faceMatchResult, t = {} } = this.props;
        const token = new URLSearchParams(window.location.search).get('token') || localStorage.getItem('token');;

        try {
            const { fields, alerts } = await convertProcessedData(data, Encrypt.encryptRSA);

            const key = await Encrypt.generateAESKey();
            const iv = await Encrypt.generateIV();

            const images = {
                faceImage: await Encrypt.encryptAES(data.images.face, key, iv),
                signImage: await Encrypt.encryptAES(data.images.signature, key, iv),
                frontImage: await Encrypt.encryptAES(data.images.docFront, key, iv),
                backImage: await Encrypt.encryptAES(data.images.docBack, key, iv),
                selfieImage: await Encrypt.encryptAES(faceMatchResult.selfie, key, iv),
                key: await Encrypt.encryptRSA(key, 'key'),
                iv: await Encrypt.encryptRSA(iv, 'iv'),
            }

            const payload = {
                card: {
                    alerts,
                    authenticationResult: ResultEnum[data.Result],
                    fields,
                    images,
                    isMatch: faceMatchResult.isMatch,
                    matchScore: faceMatchResult.score,
                    transactionId: data.InstanceId,
                },
                eula_agree: true,
                opType: 'desktop',
            };

            fetch(ApiService.IfgApiCallsList['All/SEND_DATA'], {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
              .then((res) => {
                  if (res.status !== 200) {
                      this.setState({
                          sendingDataError: t("There's a problem with sending data to server. Please contact IFG representative"),
                          sendingData: false,
                      });
                  } else {
                      this.setState({ sendingData: false });
                  }

              })
              .catch(err => {
                  console.error('err', err);
                  this.setState({
                      sendingDataError: JSON.stringify(err) || t('Something went wrong'),
                      sendingData: false,
                  });
              })
        } catch(e) {
            console.error('ERRROR', e)
            this.setState({
                sendingDataError: JSON.stringify(e) || t('Something went wrong'),
                sendingData: false,
            });
        }

    };

    resetStoreAndRedirect() {
        this.props.resetConfig();
        this.props.resetIDProperties();
        this.props.resetProcessedData();
        window.location.replace('https://www.interfacefinancial.com/');
    }

    render() {
        const { t } = this.props;
        const { sendingData, sendingDataError } = this.state;

        if (!this.props.resultData) {
            return <Processing/>
        }
        return (
            <Fragment>

                <Header/>

                <div className='body column results'>

                    <div className='wrapper'>
                        {sendingData && (
                          <>
                              <div
                                className='row wrapper description_container'
                                style={{ justifyContent: 'center', padding: 0,  }}>
                                  <p className='description'>
                                      {t('Sending data')}...
                                  </p>
                              </div>
                              <Spinner withoutHeader />
                          </>
                        )}
                        {sendingDataError && (
                          <>
                              <div
                                className='row wrapper description_container'
                                style={{ justifyContent: 'center', padding: 0,  }}>
                                  <p className='description'>
                                      {sendingDataError}
                                  </p>
                              </div>
                          </>
                        )}
                        {!sendingData && !sendingDataError && (
                          <>
                            <div
                              className='row wrapper description_container'
                              style={{ justifyContent: 'center', padding: 0,  }}>
                                <p className='description'>
                                    {t('Thank you!').toUpperCase()}
                                </p>
                            </div>
                            <a className='btn outline ifg' onClick={() => window.location.replace('https://www.interfacefinancial.com/')}>
                                <p className={'buttonBgText'}>{t('OK')}</p>
                            </a>
                          </>
                        )}
                    </div>

                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        faceMatch: state.processedData.faceMatch,
        faceMatchResult: state.processedData.faceMatchResult,
        liveness: state.processedData.liveness,
        resultData: state.processedData.result,
        cardType: state.idProperties.cardType
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({processID, resetProcessedData, resetConfig, resetIDProperties}, dispatch);
    return {...actions, dispatch};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IDPassport));
