import React, {Component} from 'react';
import Header from './Header';
import Encrypt from '../utilities/Encrypt';

export default class Spinner extends Component {
  render() {
    const { error, withoutHeader = false } = this.props;
    return (
      <>
        {!withoutHeader && (<Header />)}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          {error ? (
            <div>{error}</div>
          ) : (
            <div className="spinner" onClick={() => console.log('Encrypt', Encrypt.publicKey, Encrypt.publicKeyName)}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
      </>
    );
  }
}

