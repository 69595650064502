import axios from 'axios'
import Encrypt from "../../utilities/Encrypt";
/*
 global window
 */

const client = (() => {
    return axios.create({
        baseURL: Encrypt.auth.faceMatchEndpoint
    });
})();

const request = function(options, store) {
    const onSuccess = function(response) {
        console.debug('Request Successful!', response);
        return response.data;
    };

    const onError = function(error) {
        return Promise.reject(error.response || error.message);
    };

    options.headers = {
        "Authorization": `${Encrypt.auth.token_type} ${Encrypt.auth.access_token}`,
        'Accept': 'application/json;charset=utf-8',
        'Content-Type': 'application/json;charset=utf-8',
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};


export default request;
