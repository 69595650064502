import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withTranslation } from "react-i18next";

import {resetProcessedData} from "../actions/processDataActions";
import {resetConfig} from "../actions/configActions";
import {resetIDProperties} from "../actions/idPropertiesActions";

class DefaultError extends Component {

    constructor(props) {
        super(props);

        this.state = {
            retryLastStep: false
        };

        this.retry = this.retry.bind(this);
    }

    retry() {
        if (!this.state.retryLastStep) {
            this.props.resetConfig();
            this.props.resetIDProperties();
            this.props.resetProcessedData();
            this.props.history.push('/');
        } else {
            this.props.history.goBack();
        }
    }

    componentDidMount() {
        let {history} = this.props;
        if (history && history.location && history.location.state) {
            this.setState({
                retryLastStep: history.location.state.retryLastStep
            })
        }
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <div className='body column'>
                    <div className='wrapper'>
                        <div className='column description_container'>
                            <img alt='idscango' className='icon' src={require('../../assets/images/icon_attention@2x.png')}/>
                            <p className='description'>{t('Unable to detect ID.')}</p>
                        </div>
                        <div className='instructions'>
                            <ul>
                                <li>{t('Place ID close to device.')}</li>
                                <li>{t('Ensure sufficient light.')}</li>
                                <li>{t('Hold device steady.')}</li>
                                <li>{t('Make sure all edges of the ID are visible.')}</li>
                                <li>{t('Make sure there are no glare and shadows on the ID.')}</li>
                            </ul>
                            <div className='example_list'>
                                <div className='example'>
                                    <img alt='idscango' className='image' src={require('../../assets/images/image_correct.jpg')}/>
                                    <div className="example_text">
                                        <img alt='idscango' className='icon' src={require('../../assets/images/icon_checked@2x.png')}/>
                                        <p>{t('correct')}</p>
                                    </div>
                                </div>
                                <div className='example'>
                                    <img alt='idscango' className='image' src={require('../../assets/images/image_incorrect.jpg')}/>
                                    <div className="example_text">
                                        <img alt='idscango' className='icon' src={require('../../assets/images/icon_attention@2x.png')}/>
                                        <p>{t('incorrect')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'btn ifg'} onClick={this.retry} >
                            <p className={'buttonBdText'}>{t('Retry')}</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({resetProcessedData, resetConfig, resetIDProperties}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DefaultError));
