import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import {PersistGate} from 'redux-persist/es/integration/react';
import {isMobile} from "react-device-detect";
import {Provider} from 'react-redux';
import { withTranslation } from 'react-i18next';

import CapturePhoto from './screens/CapturePhoto';
import EulaPage from './screens/Eula';
import CaptureSelfie from './screens/CaptureSelfie';
import Results from './screens/Results/index';
import Error from './screens/Error/index';
import Spinner from './screens/Spinner';
import "./styles/main.css";
import ProcessedImageResult from "./screens/ProcessedImageResult";
import AcuantReactCamera from "./screens/AcuantReactCamera";
import IDPassport from "./screens/Results/IDPassport";
import Encrypt from './utilities/Encrypt';

/*
global Raven
 */

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAcuantSdkLoaded: false,
            getPKLoaded: false,
            getPKError: '',
        }
        this.isInitialized = false;
        this.isIntializing = false;
    }

    componentDidMount() {
        if (!isMobile) {
            this.props.routerHistory.replace('/error/mobileonly');
            document.body.classList.add('mobile-only');
            this.setState({isAcuantSdkLoaded: true, getPKLoaded: true });
        } else {
            if (!this.props.config) {
                this.props.routerHistory.replace(`/${this.props.routerHistory.location.search}`);
            }
            this.loadEncrypt();
        }
    }

    loadScript() {
        const sdk = document.createElement("script");
        sdk.src = "AcuantJavascriptWebSdk.min.js";
        sdk.onload = () => window.loadAcuantSdk();
        window.onAcuantSdkLoaded = () => this.initialize();
        document.body.appendChild(sdk);

        const camera = document.createElement("script");
        camera.src = "AcuantCamera.min.js";
        document.body.appendChild(camera);

        const passiveLiveness = document.createElement("script");
        passiveLiveness.src = "AcuantPassiveLiveness.min.js";
        document.body.appendChild(passiveLiveness);
    }

    async loadEncrypt() {
        const token = new URLSearchParams(window.location.search).get('token') || localStorage.getItem('token');

        console.log(token);
        if (token) {
            localStorage.setItem('token', token);
            const {
                pkError, publicKeyName, AUTH_TOKEN, SUBSCRIPTION_ID
            } = await Encrypt.getPublicKey(token, this.props.t);
            if (publicKeyName && AUTH_TOKEN && SUBSCRIPTION_ID) {
                this.setState({ getPKLoaded: true });
                this.loadScript();
            } else {
                this.setState({ getPKError: pkError || this.props.t('Something went wrong') })
            }
        } else {
            this.setState({ getPKError: this.props.t('Token is missing') })
        }
    }

    componentDidCatch(error, errorInfo) {
        this.props.routerHistory.push('/error/default');
    }

    componentWillUnmount() {
        localStorage.removeItem('AUTH_TOKEN');
        localStorage.removeItem('SUBSCRIPTION_ID');
    }

    initialize() {
        if (!this.isInitialized && !this.isIntializing) {
            this.isIntializing = true;

            if (!this.isOldiOS()) {
                window.AcuantJavascriptWebSdk.startWorkers(this.initDone.bind(this));
            } else {
                window.AcuantJavascriptWebSdk.startWorkers(this.initDone.bind(this), [window.AcuantJavascriptWebSdk.ACUANT_IMAGE_WORKER]);
            }
        }
    }

    isOldiOS() {
        let ua = navigator.userAgent;
        let keyPhrase = "iPhone OS";
        const keyPhrase2 = "iPad; CPU OS";
        let index = ua.indexOf(keyPhrase);
        if (index < 0) {
            keyPhrase = keyPhrase2;
            index = ua.indexOf(keyPhrase);
        }
        if (index >= 0) {
            let version = ua.substring(index + keyPhrase.length + 1, index + keyPhrase.length + 3);
            try {
                let versionNum = parseInt(version);
                if (versionNum && versionNum < 13) {
                    return true;
                } else {
                    return false;
                }
            } catch (_) {
                return false;
            }
        } else {
            return false;
        }
    }

    initDone() {
        this.isInitialized = true;
        this.isIntializing = false;
        this.setState({
            isAcuantSdkLoaded: true
        })
        console.log("initialize succeded");
    }

    render() {
        console.log('APP render', this.state);

        const { isAcuantSdkLoaded, getPKLoaded, getPKError } = this.state;

        return (
            <div className={'mainContent'}>
                {(!isAcuantSdkLoaded || !getPKLoaded) && (<Spinner error={getPKError}/>)}
                {isAcuantSdkLoaded && getPKLoaded && (
                    <Provider store={this.props.store}>
                        <PersistGate loading={null} persistor={this.props.persistor}>
                            <ConnectedRouter history={this.props.routerHistory}>
                                <Switch>
                                    <Redirect exact from="/" to="/eula"/>
                                    <Route path='/eula' exact component={EulaPage}/>
                                    <Route path="/capture/photo" exact component={CapturePhoto}/>
                                    <Route path="/capture/camera" exact component={AcuantReactCamera}/>
                                    <Route path="/photo/confirm" exact component={ProcessedImageResult} />
                                    <Route path="/capture/selfie" exact component={CaptureSelfie}/>
                                    <Route path='/results' component={Results}/>
                                    <Route path="/error" component={Error}/>
                                </Switch>
                            </ConnectedRouter>
                        </PersistGate>
                    </Provider>
                )}
            </div>
        );
    }
}

export default withTranslation()(App);
