// Detect type of device.
export const isMobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Opera: () => navigator.userAgent.match(/Opera Mini/i),
  Windows: () => navigator.userAgent.match(/IEMobile/i),
  any: () => (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()),
};

export const dataURLtoBlob = dataURL => {
  // Decode the dataURL
  const binary = atob(dataURL.split(',')[1]);
  // Create 8-bit unsigned array
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  // Return our Blob object
  return new Blob([new Uint8Array(array)], { type: 'image/jpg' });
};

export const blobToBase64 = blob => new Promise(
  resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  },
);

export const AssureIDResultEnum = {
  Unknown: 0,
  Passed: 1,
  Failed: 2,
  Skipped: 3,
  Caution: 4,
  Attention: 5,
};

export const ResultEnum = {
  0: 'Unknown',
  1: 'Passed',
  2: 'Failed',
  3: 'Skipped',
  4: 'Caution',
  5: 'Attention',
};

export const convertProcessedData = async (data, encryptRSA) => {
  // getting fields for payload
  const safeFieldKeys = [
    'Full Name', 'Given Name', 'Name Prefix', 'Name Suffix', 'Surname', 'Birth Date', 'Document Class Code', 'Document Class Name',
  ];
  const fields = {};
  await Promise.all(data.Fields.map(async ({ Name, Value }) => {
    if (!safeFieldKeys.includes(Name)) {
      fields[Name] = await encryptRSA(Value);
      return;
    }
    fields[Name] = Value;
  }));

  // getting alerts
  const alerts = data.Alerts.map(alert => ({
    description: alert.Description || 'null',
    disposition: alert.Disposition || 'null',
    Id: alert.Id || 'null',
    information: alert.Information || 'null',
    model: alert.Model || 'null',
    name: alert.Name || 'null',
    result: String(alert.Result),
    key: alert.Key || 'null',
  }));

  return ({ fields, alerts });
};

export const str2ab = str => {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export function arrayBufferToBase64( buffer ) {
  var binary = '';
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}
