import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

class MobileOnly extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        return(
            <Fragment>

                <div className='body column'>

                    <div className='column wrapper description_container desktop_error'>
                        <p className={'description'}>{t('IFG idScan App is not available for Desktop computers.')}</p>
                        <p className={'description'}>{t('Please open it on a Mobile device.')}</p>
                    </div>

                </div>

            </Fragment>
        )
    }

}

export default withTranslation()(MobileOnly)
