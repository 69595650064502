import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from "react-i18next";

import App from './App';
import {unregister} from './registerServiceWorker';
import {store, persistor, history} from './store';
import i18n from './utilities/i18n.config';

ReactDOM.render(
  <Suspense fallback="">
    <I18nextProvider i18n={i18n}>
      <App routerHistory={history} store={store} persistor={persistor}/>
    </I18nextProvider>
  </Suspense>,
  document.getElementById('root'));
unregister();
