import React, { Component } from 'react';
import i18next from 'i18next';

import { localesSupported, localesToHeaders } from '../utilities/countries';

const openLanguageSelector = (evt) => {
  evt.currentTarget.classList.toggle('open');
};

class Header extends Component {
    constructor(props) {
      super(props);
      this.state = { language: '' };
    }

    componentDidMount() {
      this.setState({ language: i18next.language });
    }

    changeLanguage = (locale) => {
      i18next.changeLanguage(locale);
      localStorage.setItem('locale', locale);
      this.setState({ language: locale });
    }

    render() {
        const { language } = this.state;
        return (
            <header>
                <div className='row wrapper'>
                    <div className='logo ifg'></div>
                    <div className='languageDropDown' onClick={openLanguageSelector}>
                      <div className='visibleBlock'>
                        <img alt='globe' className="globeIcon" src={require('../assets/images/icon_globe.png')} />
                        <p>{language.charAt(0).toUpperCase() + language.substring(1,2)}</p>
                        <span></span>
                      </div>
                      <div className='choiceOfLanguage'>
                        {localesSupported
                          .sort((a, b) => a === 'EN' || a < b)
                          .map((locale, it) => (
                            <p
                              className={locale === language ? 'selected' : null }
                              key={it}
                              onClick={() => this.changeLanguage(locale)}>
                              {localesToHeaders[locale]}
                            </p>
                          ))}
                      </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
